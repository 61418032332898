@mixin light() {
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
}

@mixin regular() {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}

@mixin medium() {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

@mixin semi-bold() {
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

@mixin bold() {
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

@mixin container() {
  padding: 0 20px;

  @media ($tablet) {
    padding: 0;
    width: 720px;
    margin: 0 auto;
  }

  @media ($desktop) {
    width: 1200px;
  }

  @media ($desktop-hd) {
    width: 1360px;
  }
}