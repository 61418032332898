.faq-answer {
  padding-top: 35px;
  padding-bottom: 75px;

  &__title {
    line-height: 143%;
  }

  &__description {
    @include medium;
    font-size: 18px;
    color: $black;
    margin-bottom: 40px;
  }

  &__subtitle {
    color: $black;
    margin-bottom: 14px;
    font-size: 16px;
    @include bold;
  }

  &__short-instruction {
    @include semi-bold;
    font-size: 14px;
    color: rgba($black, 0.8);
    padding: 13px 0 13px 24px;
    position: relative;
    margin-bottom: 45px;
    white-space: pre-wrap;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: $blue;
      border-radius: 5px;
    }
  }

  &__list-wrapper {
    padding-top: 36px;
    border-top: 2px solid #F1F6FC;
  }

  &__item-image-wrapper {
    border-radius: 20px;
    overflow: hidden;
  }

  &__image-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  &__item-description {
    font-size: 18px;
    @include semi-bold;
    color: rgba(#364663, 0.8);
    text-align: center;
  }
}

@media ($desktop) {
  .faq-answer {
    width: 912px;
    padding: 75px 0;
    margin: 0 auto;

    &__title {
      font-size: 55px;
    }

    &__description {
      font-size: 30px;
      margin-bottom: 60px;
    }

    &__subtitle {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__short-instruction {
      font-size: 18px;
    }

    &__item-image-wrapper {
      max-width: 280px;
    }

    &__image-list {
      flex-direction: row;
      gap: 20px;
      justify-content: center;
    }
  }
}