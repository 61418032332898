//colors
$black: #364663;
$black-08: rgba(54, 70, 99, 0.8);
$white: #ffffff;
$yellow: #F2BB05;
$red: #E84855;
$blue: #3185FC;
$orange: #EA9010;
$violet: #6F58FF;
$green: #26A96C;
$light-green: #4CAF50;
$light-grey: #F1F6FC;
$telegram: #27A3E1;
$instagram: #EF476F;
$faq-colors: #FFBD00, #3185FC, #4CAF50, #E84855, #087592, #FFA500, #8F8F8F;


//breakpoints
$tablet: 'min-width: 768px';
$desktop: "min-width: 1280px";
$desktop-hd: "min-width: 1400px";
$retina: "min-resolution: 1.5dppx), (min-resolution: 144dpi";
$max-tablet: "max-width: 767px";
$max-desktop: "max-width: 1279px";
