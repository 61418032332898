.faq {
  padding-top: 35px;
  padding-bottom: 75px;

  &__title {
    line-height: 143%;
  }

  &__subtitle {
    @include medium;
    font-size: 18px;
    color: $black;
    margin-bottom: 20px;
  }

  &__page-description {
    @include semi-bold;
    font-size: 16px;
    line-height: 150%;
    color: $black;
    margin-bottom: 35px;
  }

  &__list-title {
    @include bold;
    color: $black;
    font-size: 18px;
    line-height: 143%;
    display: block;
    margin-bottom: 20px;
  }

  &__item-link {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: $black;
    position: relative;
    padding: 10px 36px 10px 0;
    gap: 10px;
    align-items: flex-start;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url('../../images/arrow-right.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }

  &__item {
    border-bottom: 2px solid #F1F6FC;

    @for $i from 1 through length($faq-colors) {
      &:nth-child(7n+#{$i}) .faq__image-wrapper {
        background-color: nth($faq-colors, $i);
      }
    }
  }

  &__image-wrapper {
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
  }

  &__item-title {
    @include semi-bold;
    font-size: 16px;
    line-height: 143%;
  }

  &__item-description {
    @include medium;
    font-size: 14px;
    line-height: 143%;
    color: rgba($black, 0.8);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

@media ($desktop) {

  .faq {
    width: 912px;
    padding: 75px 0;
    margin: 0 auto;

    &__subtitle {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__page-description {
      font-size: 18px;
      margin-bottom: 35px;
    }

    &__list-title {
      font-size: 24px;
      margin-bottom: 40px;
    }

    &__item-link {
      padding: 20px 130px 20px 20px;

      &::before {
        width: 54px;
        height: 54px;
      }
    }

    &__item-title {
      font-size: 18px;
    }

    &__item-description {
      font-size: 15px;
    }
  }
}