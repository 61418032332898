.privacy-policy {
  padding-top: 70px;
  padding-bottom: 70px;

  &__container {
    padding: 0 16px;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__title {
    font-size: 48px;
    margin: 0 0 52px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 38px;
  }

  &__subtitle {
    @include bold;
    font-size: 22px;
    text-transform: uppercase;
    margin: 60px 0 25px;
  }

  &__list {
    list-style-type: disc;
  }

  &__link {
    color: $yellow;
  }
}