.page-header {
  position: relative;
  z-index: 10;
  padding-top: 12px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container;
  }

  &__logo {
    width: 138px;
    height: 51px;

    img {
      width: 100%;
    }
  }

  &__menu-btn {
    width: 30px;
    height: 30px;
    border: none;
    padding: 0;
    position: relative;
    background: url('../../images/menu-icon.svg') no-repeat center;
    background-size: 100%;

    &--opened {
      background-image: url('../../images/close-icon.svg');
    }
  }
}

@media ($desktop) {

  .page-header {
    padding: 0;
    margin-bottom: 55px;

    &__container {
      width: auto;
      margin: 0;
    }

    &__logo {
      width: 197px;
      height: 75px;
    }
  }
}