.promo {
  height: 100%;

  &__container {
    width: 320px;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__rings-wrapper {
    margin-right: 21px;
  }

  &__welcome-text {
    font-size: 19px;
    line-height: 143%;
    @include semi-bold;
    color: $black;
    display: block;
    margin-bottom: 10px;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 23px;
    line-height: 143%;
    color: $black;
    @include bold;
    letter-spacing: 0.02em;

    .desktop {
      display: none;
    }
  }
}

@media ($tablet) {
  .promo {
    height: 100%;

    &__container {
      width: 720px;
    }

    &__rings-wrapper {
      margin-right: 69px;
    }

    &__welcome-text {
      font-size: 20px;
      margin-bottom: 1px;

      br {
        display: none;
      }
    }

    &__description {
      font-size: 40px;
      width: 565px;

      .desktop {
        display: block;
      }

      .mobile {
        display: none;
      }
    }
  }
}

@media ($desktop-hd) {
  .promo {
    width: 912px;

    &__container {
      margin: 0;
      width: auto;
    }
  }
}