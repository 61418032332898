.feedback {

  &__container {
    padding: 54px 0;
    width: 940px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
  }

  &__avatar {
    min-width: 120px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
    gap: 60px;
  }

  &__name {
    @include semi-bold;
    font-size: 55px;
    line-height: 143%;
    color: $black;
  }

  &__author-socials {
    display: flex;
    gap: 10px;

    &--mobile {
      display: none;
      margin-top: 10px;
    }
  }

  &__author-description {
    @include medium;
    font-size: 26px;
    line-height: 143%;
    color: $black;
  }

  &__author-score {
    margin-top: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__title {
    @include bold;
    font-size: 24px;
    line-height: 143%;
    margin-bottom: 10px;

    &--blue {
      color: $blue;
    }

    &--red {
      color: $red;
    }

    &--green {
      color: $green;
    }
  }

  &__text {
    @include semi-bold;
    font-size: 18px;
    line-height: 150%;
    color: $black-08;
    white-space: pre-wrap;
  }
}

@media ($max-tablet) {
  .feedback {

    &__container {
      width: auto;
      padding: 36px 16px 0;
    }

    &__header {
      gap: 20px;
    }

    &__name-wrapper {
      flex-direction: column;
      gap: 10px;
    }

    &__author-socials {
      display: none;

      &--mobile {
        display: flex;
      }
    }

    &__name {
      font-size: 32px;
    }

    &__author-description {
      font-size: 18px;
    }

    &__author-score {
      margin-top: 0;
    }
  }
}

@media ($max-desktop) {

  .feedback {
    padding: 36px 0;

    &__container {
      width: auto;
      @include container;
    }
  }
}