.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: $white;
  padding-top: 95px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    @include container;
    overflow: auto;
    height: calc(100% - 100px);
    padding-bottom: 100px;
  }

  &__category {
    color: $black;
    font-size: 22px;
    margin-bottom: 15px;
  }

  &__list {
    margin-bottom: 24px;
  }

  &__item {
    width: 100%;
    //height: 64px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 3px;

    //&--mobile {
    //  background: rgba(232, 72, 85, 0.1);
    //}
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px 16px 16px 42px;
    text-decoration: none;
    color: $black;
    position: relative;
    transition-duration: 300ms;

    &:hover {
      background-color: $light-grey;
    }
    
    &--web {
      &--current {
        background-color: rgba($yellow, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($yellow, 0.1);
      }
    }

    &--mobile {
      &--current {
        background-color: rgba($red, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($red, 0.1);
      }
    }

    &--game {
      &--current {
        background-color: rgba($blue, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($blue, 0.1);
      }
    }

    &--physic {
      &--current {
        background-color: rgba($orange, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($orange, 0.1);
      }
    }

    &--esenin {
      &--current {
        background-color: rgba($green, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($green, 0.1);
      }
    }

    &--universe-say {
      &--current {
        background-color: rgba($violet, 0.1);
        cursor: auto;
      }

      &:hover {
        background-color: rgba($violet, 0.1);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -7px;
      left: 14px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
    
    &--web::before {
      background-color: $yellow;
    }

    &--mobile::before {
      background-color: $red;
    }

    &--game::before {
      background-color: $blue;
    }

    &--physic::before {
      background-color: $orange;
    }

    &--esenin::before {
      background-color: $green;
    }

    &--universe-say::before {
      background-color: $violet;
    }
  }

  &__project-name {
    color: $black;
    font-size: 18px;
    margin-bottom: 3px;
  }

  &__project-descr {
    color: $black-08;
    font-size: 13px;
  }

  &__bottom-navigation {
    width: 100%;
    padding: 12px 20px;
    border-top: 1px solid $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
  }

  &__socials {
    display: flex;
    gap: 10px;
  }

  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-size: 25px 25px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
    transition-duration: 300ms;

    svg {
      width: 25px;
      height: 25px;
    }

    &--telegram {
      background-color: rgba(39, 163, 225, 0.1);

      &:hover {
        background-color: $telegram;

        .fill {
          fill: $white;
        }

        .stroke {
          stroke: $white;
        }
      }
    }

    &--instagram {
      background-color: rgba(239, 71, 111, 0.1);

      &:hover {
        background-color: $instagram;

        path {
          fill: $white;
        }
      }
    }
  }
}

@media ($desktop) {
  .menu {
    position: static;
    padding-top: 0;
    z-index: 0;
    height: auto;
    flex-grow: 1;

    &__container {
      width: 100%;
      overflow: visible;
      height: auto;
      padding-bottom: 0;
    }

    &__item {}

    width: 330px;

    &__bottom-navigation {
      border:none;
      padding: 0;
      position: static;
    }
  }
}