@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: 'Poppins';

  src: url('../fonts/Poppins-Light.woff2') format('woff2'),
  url('../fonts/Poppins-Light.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'Poppins';

  src: url('../fonts/Poppins-Regular.woff2') format('woff2'),
  url('../fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: 'Poppins';

  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
  url('../fonts/Poppins-Medium.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: 'Poppins';

  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
  url('../fonts/Poppins-SemiBold.woff') format('woff');
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: 'Poppins';

  src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
  url('../fonts/Poppins-Bold.woff') format('woff');
}

html {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

h1, h2, h3, p {
  margin: 0;
}

.red {
  color: $red;
}

.blue {
  color: $blue;
}

.yellow {
  color: $yellow;
}

.light-green {
  color: $light-green;
}

.orange {
  color: $orange;
}

.page-wrapper {
  height: 100%;
}

.page-main {
  height: calc(100% - 67px);

  &>div {
    height: 100%;
  }
}


@media ($desktop) {

  body {
    margin-left: 382px;
  }

  .page-wrapper {
    display: flex;
    height: auto;
    min-height: 100%;
    //height: 100%;
  }

  .sidebar-menu {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 54px 20px 15px 30px;
    border-right: 2px solid $light-grey;
    overflow: auto;
  }

  .page-main {
    width: 100%;
    height: auto;
    min-height: 100%;
    display: flex;
    justify-content: center;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}
