.reviews {

  &__container {
    padding: 54px 0;
    width: 940px;
  }

  &__title {
    @include semi-bold;
    font-size: 55px;
    line-height: 143%;
    color: $black;
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    gap: 20px 30px;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__item {
    border-radius: 20px;
    overflow: hidden;
    width: 212px;
    height: 426px;
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    background: #e3e3e3;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 227px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 90.42%);
    }
  }

  &__link {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__item-info {
    width: 100%;
    position: absolute;
    bottom: 15px;
    z-index: 1;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name {
    @include semi-bold;
    font-size: 26px;
    line-height: 143%;
    text-align: center;
  }

  &__text {
    @include medium;
    font-size: 16px;
    line-height: 143%;
  }
}

@media ($max-tablet) {
  .reviews {

    &__title {
      font-size: 36px;
    }
    &__container {
      width: auto;
      padding: 36px 16px 0;
    }
    &__list {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    &__item {
      width: 100%;
    }
  }
}

@media ($max-desktop) {

  .reviews {
    padding: 36px 0;

    &__title {
      font-size: 42px;
    }

    &__container {
      width: auto;
      @include container;
    }

    &__item {
      width: 220px;
    }
  }
}